<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <div class="d-flex align-items-center">
        <label class="inline-label">Show</label>
        <b-form-select v-model="perPage" :options="perPageOptions" @change="handlePerPage" />
      </div>
      <label class="inline-label d-none d-md-inline">Jumlah Pemesan : {{ tableData.length }} Orang</label>

      <div class="d-flex">
        <b-button variant="danger" @click="resetSearch()" class="ml-1">
          <feather-icon icon="RefreshCcwIcon" />
        </b-button>

        <search :add-button="false" @addButtonAction="$router.push({ name: 'jenisacara/form' })"
          @handleAdvanceSearch="handleSearch" @resetAdvanceSearch="handleReset" />
      </div>
    </div>

    <b-table class="border mt-1" :fields="tableField" :items="tableData" show-empty responsive hover :busy="loading">
      <template #table-busy>
        <div class="text-center text-danger my-2" style="padding:50px 0">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(action)="data">
        <b-button-group>
          <b-button class="btn btn-sm p-0 m-0" variant="flat-dark" @click.prevent="handleEdit(data.item)" v-b-tooltip.hover title="Edit">
            <feather-icon size="20" icon="EditIcon" />
          </b-button>

          <!-- <b-button variant="flat-dark" v-b-tooltip.hover title="Telephone"
            @click.prevent="openModalKeterangan(data.item.kodeorder, data.item.namapemesan, data.item.cs_sudah_telp)">
            <feather-icon size="20" icon="PhoneIcon" />
          </b-button>

          <b-button variant="flat-dark" v-b-tooltip.hover title="Confirm"
            @click.prevent="openModalConfirm(data.item.kodeorder, data.item.namapemesan, data.item.cs_sudah_telp, data.item.cs_note_telp)">
            <feather-icon size="20" icon="SendIcon" />
          </b-button> -->
        </b-button-group>
      </template>

      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(namapemesan)="data">
        <span class="text-nowrap">{{ data.item.namapemesan }}</span>
      </template>

      <template #cell(tanggalacara)="data">
        <span class="text-nowrap">{{ moment(data.item.tanggalacara).format('DD-MM-YYYY') }}</span>
      </template>

      <template #cell(jamacara)="data">
        <span class="text-nowrap">{{ data.item.jamacara }}</span>
      </template>

      <template #cell(alamatacara)="data">
        <span class="text-nowrap">{{ capitalizeFirstLetter(data.item.alamatacara) }}</span>
      </template>

      <template #cell(kotaacara)="data">
        <span class="text-nowrap">{{ data.item.kotaacara }}</span>
      </template>

      <template #cell(totalharga)="data">
        <div class="text-right btn btn-sm btn-danger">
          {{ convertToIDR(data.item.totalharga-data.item.discount) }}
        </div>
      </template>

      <template #cell(cs_sudah_telp)="data">
        <span class="text-nowrap">
          {{
          data.item.cs_sudah_telp === "0" ? 'Belum Ditelepon' :
            data.item.cs_sudah_telp === "1" ? 'Sudah Ditelepon' : ''
        }}
        </span>
      </template>

      <template #cell(cs_note_telp)="data">
        <b-link href="" @click.prevent="openModalCatatan(data.item.kodeorder, data.item.namapemesan, data.item.cs_sudah_telp, data.item.cs_note_telp)">
          <span class="text-nowrap">
            {{ data.item.cs_note_telp.slice(0, 30) }}
            <!-- <span v-if="data.item.cs_note_telp.length > 30">...(Lihat Detail)</span> -->
          </span>
        </b-link>
      </template>

      <template #empty="scope">
        <div class="text-center my-75">
          <b-img :src="ClipboardsSecondary" />
          <div class="text-secondary font-small-3 mt-75">
            Tidak ada data
          </div>
        </div>
      </template>
    </b-table>

    <b-modal id="modal-keterangan" title="Status Telepon" :no-close-on-backdrop="true" hide-footer
      v-model="isShowModalKeterangan">
      <b-row>
        <b-col md="12">
          <validation-provider #default="{ errors }" name="note" rules="required">
            <b-form-group label="Catatan" label-for="note">
              <b-form-textarea id="note" placeholder="Masukkan catatan" maxlength="100" v-model="note" />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="dark" @click="handleTutup">Cancel</b-button>
        <b-button variant="primary" @click.prevent="handleSave(selectedData)">Save</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-aktif" title="Change Status" :no-close-on-backdrop="true" hide-footer
      v-model="isShowModalConfirm">
      <b-row>
        <b-col md="12">
          <validation-provider #default="{ errors }" name="status" rules="required">
            <b-form-group label="Status" label-for="status" :state="errors.length > 0 ? false : null">
              <v-select id="status" v-model="statusorder" :options="statusoptionfield" label="text"
                placeholder="Pilih" />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>

        <b-col md="12">
          <validation-provider #default="{ errors }" name="note" rules="required">
            <b-form-group label="Keterangan" label-for="note">
              <b-form-textarea id="note" placeholder="Masukkan keterangan" maxlength="100" v-model="note" />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="dark" @click="handleTutup">Cancel</b-button>
        <b-button variant="primary" @click="handleConfirm(selectedData)">OK</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-aktif" title="Detail Catatan" :no-close-on-backdrop="true" hide-footer
      v-model="isShowModalCatatan">
      <span>{{ note }}</span>

      <div class="d-flex justify-content-end mt-2">
        <b-button variant="dark" @click="handleTutup">OK</b-button>
      </div>
    </b-modal>

    <div class="float-right pt-2">
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
    </div>
  </b-card>
</template>

<script>
import { convertToIDR } from '@/@core/utils/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect } from 'bootstrap-vue'
import { required } from '@validations'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import Search from './Search.vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  components: {
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    required,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ToastificationContent,
    Search,
    vSelect,
  },
  data() {
    return {
      ClipboardsSecondary,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      searchVal: '',
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      tableField: [
        { key: 'no', label: 'No', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
        { key: 'action', label: 'Action', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
        { key: 'namapemesan', label: 'Nama Pemesan', thClass: 'text-center' },
        { key: 'nohp', label: 'No. Handphone', thClass: 'text-center' },
        { key: 'cs_sudah_telp', label: 'Sudah Ditelp', class: 'text-center', thClass: 'text-center' },
        { key: 'cs_note_telp', label: 'Catatan', thClass: 'text-center' },
        { key: 'cabang', label: 'Cabang', class: 'text-center', thClass: 'text-center' },
        { key: 'tanggalacara', label: 'Tanggal Acara', thClass: 'text-center' },
        { key: 'jamacara', label: 'Jam Acara', thClass: 'text-center' },
        { key: 'alamatacara', label: 'Alamat', thClass: 'text-center' },
        { key: 'kotaacara', label: 'Kota Acara', class: 'text-center', thClass: 'text-center' },
        { key: 'totalharga', label: 'Total Harga', class: 'text-right', thClass: 'text-center' },
        { key: 'token', label: 'Token', thClass: 'text-center' },
      ],
      tableData: [],
      loading: false,
      selectedData: null,
      selectedNamaData: null,
      isShowModalKeterangan: false,
      isShowModalConfirm: false,
      isShowModalCatatan: false,
      note: '',
      cs_sudah_telp: '',
      statusorder: '',
      statusoptionfield: ['Order Confirm', 'Order Cancel'],
    }
  },
  watch: {
    currentPage(x, y) {
      if (x !== y) {
        this.getListData()
      }
    },
  },

  mounted() {
    this.getListData()
  },
  methods: {
    moment,
    convertToIDR,
    capitalizeFirstLetter(text) {
      if (!text) return '';

      return text
        .toLowerCase()
        .replace(/\b\w/g, match => match.toUpperCase());
    },

    handleSearch(val) {
      this.searchVal = val
      this.getListData()
    },

    handleReset(val) {
      this.searchVal = val
      this.getListData();
    },

    handlePerPage(val) {
      this.perPage = val
      this.getListData()
    },

    openModalKeterangan(id, name, cs_sudah_telp) {
      this.selectedData = id
      this.selectedNamaData = name
      this.cs_sudah_telp = parseInt(cs_sudah_telp)

      if (this.cs_sudah_telp == 0) {
        this.isShowModalKeterangan = true;
      } else {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'info',
            text: 'Konsumen ini sudah ditelp sebelum nya',
          },
        });
      }
    },

    openModalConfirm(id, name, cs_sudah_telp, cs_note_telp) {
      this.selectedData = id
      this.selectedNamaData = name
      this.cs_sudah_telp = parseInt(cs_sudah_telp)

      if (this.cs_sudah_telp != 0 && cs_note_telp != '') {
        this.isShowModalConfirm = true;
      } else {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'info',
            text: 'Konsumen ini belum ditelp dan konfirmasi pesanan',
          },
        });
      }
    },

    openModalCatatan(id, name, cs_sudah_telp, cs_note_telp) {
      this.selectedData = id
      this.selectedNamaData = name
      this.note = cs_note_telp
      this.isShowModalCatatan = true;
    },

    handleTutup() {
      this.isShowModalKeterangan = false;
      this.isShowModalConfirm = false;
      this.isShowModalCatatan = false;
    },

    getListData() {
      this.loading = true;
      const params = {
        keyword: '',
        namapemesan: this.searchVal.namapemesan ?? '',
        tanggalacara: this.searchVal.tanggalacara ?? '',
        nohp: this.searchVal.nohp ?? '',
        alamatacara: this.searchVal.alamat ?? '',
        cs_sudah_telp: this.searchVal.cs_sudah_telp === 'Sudah' ? 1 : this.searchVal.cs_sudah_telp === 'Belum' ? 0 : '',
        token: this.searchVal.token ?? '',
        cabang: this.searchVal.cabang ?? '',
        page: this.currentPage,
        perPage: this.perPage
      };

      this.$http.get('/api/transaksi/detailbystatus/1', { params: params }).then(res => {
        this.loading = false;
        this.tableData = res.data.data;
        this.rows = res.data.maxPage * this.perPage;

        if (this.searchVal != '') {
          if (res.data.data.length > 0) {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Pencarian data berhasil ditemukan',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                variant: 'warning',
                text: 'Pencarian data tidak ditemukan',
              },
            });
          }
        }
      }).catch(e => {
        this.loading = false;
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
        this.tableData = [];
        this.rows = 0;
      });
    },

    resetSearch() {
      this.searchVal = ''
      this.getListData()
    },

    handleEdit(item) {
      this.$router.push(`/transaksi/submitcustomer/form/${item.kodeorder}`);
    },

    handleSave(kodeorder) {
      if (!this.validateFormPhone()) {
        return
      }

      const params = {
        kodeorder: kodeorder,
        cs_note_telp: this.note
      }

      this.$http.post('/api/transaksi/editnotetransaksi', params).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              variant: 'danger',
              text: res.data.message ?? 'Gagal menyimpan keterangan, silahkan hubungi admin',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Berhasil menyimpan keterangan',
            },
          });
          this.isShowModalKeterangan = false
          this.note = ''
          this.getListData()
        }
      }).catch(e => {
        this.loading = false
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan keterangan, silahkan hubungi admin',
          },
        });
        this.loading = false
      })
    },

    handleConfirm(kodeorder) {
      if (!this.validateFormConfirm()) {
        return;
      }

      Swal.fire({
        title: 'Informasi',
        text: 'Apakah anda ingin merubah status order ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Ubah!'
      }).then((result) => {
        if (result.isConfirmed) {
          const params = {
            kodeorder: kodeorder,
            status: this.statusorder == 'Order Confirm' ? 2 : 9,
            cs_note_telp: this.note
          }

          this.$http.post('/api/transaksi/editstatustransaksi', params).then(res => {
            if (!res.data.status) {
              this.$toast({
                component: ToastificationContent, position: 'top-right',
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: res.data.message ?? 'Gagal merubah status, silahkan hubungi admin',
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent, position: 'top-right',
                props: {
                  title: 'Notification',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Berhasil merubah status',
                },
              });
              this.isShowModalConfirm = false
              this.note = ''
              this.statusorder = ''
              this.getListData()
            }
          }).catch(e => {
            this.loading = false
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'SlashIcon',
                variant: 'danger',
                text: 'Gagal merubah status, silahkan hubungi admin',
              },
            });
            this.loading = false
          })
        }
      });
    },

    validateFormConfirm() {
      if (this.statusorder === '' || this.note === '') {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan isi status dan keterangan sebelum mengkonfirmasi pesanan.',
          },
        });
        return false;
      }

      return true;
    },

    validateFormPhone() {
      if (this.note === '' || this.note === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi keterangan',
          },
        });
        return false
      }

      return true
    }
  }
}
</script>
